/* Container & Überschriften */
.copilotpro-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.copilotpro-container h1,
.copilotpro-container h2,
.copilotpro-container h3 {
  text-align: center;
}

/* Chat-Fenster */
.chat-window {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  padding: 10px;
  height: 300px;
  overflow-y: auto;
  margin-bottom: 10px;
}

.chat-entry {
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 5px;
}

.chat-entry.user {
  background-color: #d1e7dd;
  text-align: left;
}

.chat-entry.assistant {
  background-color: #ffe5d9;
  text-align: right;
}

.message {
  padding: 5px;
}

/* Chat Input */
.chat-input-container {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.chat-input-container input {
  flex: 1;
  padding: 8px;
  font-size: 16px;
}

.chat-input-container button {
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
}

/* Modulvorschläge */
.module-suggestions {
  background-color: #eef;
  border: 1px solid #ccd;
  padding: 10px;
  margin-top: 20px;
}

.module-suggestions ul {
  list-style: none;
  padding: 0;
}

.module-suggestions li {
  margin-bottom: 5px;
}

.module-suggestions button {
  display: block;
  margin: 10px auto;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
}

/* Modulparameter */
.module-parameters {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 20px;
}

.module-parameter-form {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
}

.module-parameter-form .form-group {
  margin-bottom: 10px;
}

.module-parameter-form label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.module-parameter-form input {
  width: 100%;
  padding: 5px;
  font-size: 14px;
}
