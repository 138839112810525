/* Grundlayout */
body {
  background-color: #f4f7fa;
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  color: #333;
}

/* Container für das gesamte Modul */
.container {
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

/* Chat Container */
.chat-container {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 15px;
  background-color: #fafafa;
  margin-bottom: 20px;
}

/* Chat Log (50% größer – max-height erhöht) */
.chat-log {
  max-height: 450px;  /* Ursprünglich 300px, jetzt ca. 50% größer */
  overflow-y: auto;
  padding: 10px;
  margin-bottom: 15px;
}

/* Chat-Bubbles */
.chat-entry {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  max-width: 80%;
}

.user-bubble {
  align-self: flex-end;
  background-color: #4e73df;
  color: #fff;
  border-radius: 15px 15px 0 15px;
  padding: 10px;
}

.copilot-bubble {
  align-self: flex-start;
  background-color: #e2eafc;
  color: #333;
  border-radius: 15px 15px 15px 0;
  padding: 10px;
}

/* Header innerhalb der Sprechblase */
.bubble-header {
  font-weight: bold;
  margin-bottom: 5px;
}

/* Input-Bereich */
.chat-input-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.chat-input {
  flex: 1;
  padding: 12px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s ease;
}

.chat-input:focus {
  border-color: #4e73df;
  outline: none;
}

/* Send-Button */
.btn-send {
  background-color: #4e73df;
  color: #fff;
  border: none;
  padding: 12px 20px;
  font-size: 1em;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.btn-send:hover {
  background-color: #2e59d9;
  transform: scale(1.05);
}

/* Berechnungsergebnis */
.calc-result {
  background-color: #e2eafc;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
}

.calc-result h3 {
  margin-top: 0;
  color: #4e73df;
}

/* Log-Bereich */
.log-section {
  background-color: #e2eafc;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  max-height: 200px;
  overflow-y: auto;
  margin-top: 15px;
}

.log-section h3 {
  color: #4e73df;
  margin-bottom: 10px;
}

.log-section ul {
  list-style-type: none;
  padding-left: 0;
}

.log-section li {
  padding: 5px 0;
  border-bottom: 1px solid #ddd;
}

.log-section li:last-child {
  border-bottom: none;
}

/* Toggle-Button für Logs */
.toggle-log-button {
  background-color: #4e73df;
  color: #fff;
  border: none;
  padding: 10px 15px;
  font-size: 1em;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin-bottom: 10px;
}

.toggle-log-button:hover {
  background-color: #2e59d9;
  transform: scale(1.05);
}
